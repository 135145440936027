html,
body,
#root {
  height: 100%;
  background-color: #f4fafd;
}
.todayText {
  letter-spacing: 1px;
  font-weight: lighter;
  font-size: 1.75rem;
  margin-top: 30px;
  margin-bottom: 20px;
}
.locationText {
  letter-spacing: 1px;
  font-weight: lighter;
  font-size: 1.75rem;
  margin-top: 30px;
  margin-bottom: 30px;
}
.dayText {
  letter-spacing: 1px;
  font-weight: lighter;
  font-size: 1.3rem;
  margin-top: 10px;
  margin-bottom: 0;
}
.weatherText {
  margin-bottom: 40px;
  font-size: 1.75rem;
  font-weight: lighter;
}
.tempText {
  font-family: gill sans, sans-serif;
  font-size: 3.25rem;
  font-weight: bold;
}
.smallTempText {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 15px;
}
.headerText {
  letter-spacing: 1px;
  font-weight: lighter;
  font-size: 1.75rem;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}
.mainWeatherIcon {
  height: 100px;
  width: 100px;
}
.smallWeatherIcon {
  height: 75px;
  width: 75px;
}
.iconRowWeather {
  display: flex;
  flex-direction: row;
}
.iconColumnWeather {
  display: flex;
  flex-direction: column;
}
.panelContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}
.forecastContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.weatherPanel {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  border: 5px solid white;
  border-radius: 10px;
  box-shadow: 0 0 10px lightblue;
  max-width: 600px;
}
.headerRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 600px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: space-around;
}
.weatherRow {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.weatherCell {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
